import React from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { TextArea, TextInput, RadioInputGroup, InputLabel } from '@Root/components'
import { ApplicationFormTable } from './ApplicationFormTable'

export const ApplicationForm = ({ data, programmes }) => {
  const yesNoOptions = ['No', 'Yes']
  const britishVisaStatusOptions = ['Not needed', 'Granted', 'Required', 'Indefinite leave to remain']
  const churchOfEnglandOptions = ['Ordinand', 'Independent']
  const fullTimeOptions = ['FT', 'PT']

  const fundYourCourseStudyOptions = [
    { value: 1, label: 'Self/family' },
    { value: 2, label: 'Student Finance Direct (student loan)' },
    { value: 3, label: 'Diocese/church/sponsor' },
    { value: 4, label: 'Other' },
  ]
  const hearAboutUsOption = [
    { value: 1, label: 'CTF website' },
    { value: 2, label: 'Federation House to which you applied	' },
    { value: 3, label: 'Federation student' },
    { value: 4, label: 'Federation staff member' },
    { value: 5, label: 'Federation graduate' },
    { value: 6, label: 'Family/friend/word of mouth' },
    { value: 7, label: 'Parish priest/pastor/religious leader' },
    { value: 8, label: 'Other (please specify)' },
  ]

  const getValueInMultiSelect = (dataSelect, options) => {
    const newArray = []
    if (!dataSelect) return newArray

    const data = Array.isArray(dataSelect) ? Object.values(dataSelect) : Object.keys(dataSelect)

    data.forEach(value => {
      const option = options.find(option => option.value === +value)
      if (option) newArray.push(option.label)
    })

    return newArray.reduce((acc, label, i) => acc + `${i === 0 ? '' : ', '}${label}`, '')
  }

  return (
    <>
      <div className={classes.sectionsWrapper}>
        <div className={classes.section}>
          <div className={classes.title}>1. Personal information</div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Title' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 100, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.title}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Firstname(s)' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 100, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.firstname}
              isDisabled
            />
            <InputLabel style={{ color: '#4A4A4A', position: 'absolute', left: 270 }} text='Surname' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 368, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.surname}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A', width: 80 }} text='Previous surname' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 100, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.previous_surname}
              isDisabled
            />
            <InputLabel style={{ color: '#4A4A4A', position: 'absolute', left: 270 }} text='Gender' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 368, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.sex}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A', width: 90 }} text='Known as' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 100, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data?.known_as}
              isDisabled
            />
          </div>

          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Address' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 100, width: 418, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.address}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='City' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 100, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.city}
              isDisabled
            />
            <InputLabel style={{ color: '#4A4A4A', position: 'absolute', left: 270 }} text='Postcode' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 368, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.postcode}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='DOB' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 100, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.DOB}
              isDisabled
            />
            <InputLabel style={{ color: '#4A4A4A', position: 'absolute', left: 270 }} text='Nationality' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 368, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.nationality}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Email' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 100, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.email}
              isDisabled
            />
            <InputLabel style={{ color: '#4A4A4A', position: 'absolute', left: 270 }} text='Mobile tel' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 368, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.mobile_phone}
              isDisabled
            />
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.title}>2. Nationality</div>
          <div className={classes.text}>If you are a UK citizen, please move straight on to section 3</div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Country of birth' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 368, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.country_birth ? data.country_birth.name : ''}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Country of permanent residence' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 368, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.country_of_permanent ? data.country_of_permanent.name : ''}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='UK visa status' />
            <RadioInputGroup
              style={{ position: 'absolute', left: 254 }}
              values={britishVisaStatusOptions}
              currentValue={britishVisaStatusOptions[data.british_visa_status - 1]}
              isInline
              isDisabled
            />
          </div>
          <div className={classes.row}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Is English your first language? If so, please move on to section 3' />
            <RadioInputGroup values={yesNoOptions} currentValue={yesNoOptions[data.is_english_first_language]} isInline isDisabled />
          </div>
          {!data.is_english_first_language && (
            <div className={classes.row}>
              <InputLabel
                style={{ color: '#4A4A4A', height: 'fit-content' }}
                text='If English is not your first language, please provide IELTS examination results or equivalent below. You will be asked to provide copies of any certificates during your application process. Please note that TOEFL results are not accepted by Durham University.'
              />
              <ApplicationFormTable
                style={{ width: '100%', marginTop: '10px' }}
                columnsNamesAndFields={{
                  'Title/Component': 'Title/Component',
                  Grade: 'Grade',
                  'Date of testing': 'Date of testing',
                }}
                data={data.taken_IELTS_exam}
              />
            </div>
          )}
          <div>
            <span className={classes.text}>
              ALL applicants must present a passport at interview as proof of identity. Non UK applicants should provide an officially notarised copy of their
              current passport with their application.
            </span>
          </div>
        </div>
      </div>
      <div className={classes.sectionsWrapper}>
        <div className={classes.section}>
          <div className={classes.title}>3. Your programme</div>
          <div className={classes.row}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Which study programme(s) are you interested in pursuing?' />
            <TextInput
              classNames={['borderless']}
              style={{ width: 518, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={getValueInMultiSelect(data.study_program, programmes)}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Institution' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 100, width: 418, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data?.institution}
              isDisabled
            />
          </div>
          {data?.questions.map(el => (
            <div className={classes.fixedRow}>
              <InputLabel style={{ color: '#4A4A4A' }} text={el.text} />
              <TextInput
                classNames={['borderless']}
                style={{ position: 'absolute', right: 3, width: 300, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
                value={el.value}
                isDisabled
              />
            </div>
          ))}

          <div className={classes.row}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Are you intending to to study full time or part time?' />
            <RadioInputGroup values={fullTimeOptions} currentValue={fullTimeOptions[data.full_time - 1]} isInline isDisabled />
          </div>

          <div className={classes.row}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Are you intending to pursue ordination in the Church of England or independent theological study?' />
            <RadioInputGroup values={churchOfEnglandOptions} currentValue={churchOfEnglandOptions[data.church_of_england - 1]} isInline isDisabled />
          </div>

          <div className={classes.row}>
            <InputLabel
              style={{ color: '#4A4A4A' }}
              text='If you would like to defer your entry to CTF, please state month and year of your prefered date of entry'
            />
            <TextInput
              classNames={['borderless']}
              style={{ width: 518, color: '#9B9B9B', backgroundColor: '#F5F4F3', marginTop: 10 }}
              value={data?.launching_educational}
              isDisabled
            />
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.title}>4. Education and Employment</div>
          <div className={classes.row} style={{ marginBottom: 20 }}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Educational qualifications' />
            <ApplicationFormTable
              style={{ width: '100%' }}
              columnsNamesAndFields={{ Institution: 'Institution', Subject: 'Subject', Award: 'Award', [`Result(s)`]: 'Result', Date: 'Date' }}
              data={data.educational_qualifications}
            />
          </div>
          <div>
            <span className={classes.text}>Please list in date order, most recent first.</span>
          </div>
          <div className={classes.row} style={{ marginBottom: 20 }}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Professional qualifications' />
            <ApplicationFormTable
              style={{ width: '100%' }}
              columnsNamesAndFields={{ Institution: 'Institution', Subject: 'Subject', Award: 'Award', [`Result(s)`]: 'Result', Date: 'Date' }}
              data={data.professional_qualifications}
            />
          </div>
          <div className={classes.row} style={{ marginBottom: 20 }}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Recent employment history' />
            <ApplicationFormTable
              style={{ width: '100%' }}
              columnsNamesAndFields={{ Employer: 'Employer', Role: 'Role', 'Dates from/to': 'Dates from/to', 'FT/PT': 'FT/PT' }}
              data={data.recent_employment_history}
            />
          </div>
          <div>
            <span className={classes.text}>
              Please list in date order, most recent first and please be prepared to provide copies of any certificates for academic and professional
              qualifications.
            </span>
          </div>
        </div>
      </div>
      <div className={classes.sectionsWrapper}>
        <div className={classes.section}>
          <div className={classes.title}>5. Financial Details</div>
          {/* <div>
            <span className={classes.text}>If you are not applying for ordination in the Church of England, please move straight on to section 6</span>
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Name of current church' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 368, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.name_of_current_church}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Sending diocese' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 368, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.sending_diocese}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Name of your DDO' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 368, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.name_of_your_DDO}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Date of last meeting with DDO' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 368, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.date_of_last_meeting_with_DDO}
              isDisabled
            />
          </div>

          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Email contact for your DDO' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 368, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.email_contact_for_your_DDO}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Phone contact for your DDO' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 368, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.phone_contact_fo_your_DDO}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Date of Bishops Advisory Panel' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 368, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.date_of_bishops_advisory_panel}
              isDisabled
            />
          </div>
          <div className={classes.row}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Outcome of BAP' />
            <RadioInputGroup values={outcomeOfBapOptions} currentValue={outcomeOfBapOptions[data.outcome_of_BAP - 1]} isInline isDisabled />
          </div> */}

          <div className={classes.row}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Please indicate how you plan to fund your course of study:' />
            <TextInput
              classNames={['borderless']}
              style={{ width: 518, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={getValueInMultiSelect(data.fund_your_course_study, fundYourCourseStudyOptions)}
              isDisabled
            />
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.title}>6. Supporting statement</div>
          <div className={classes.text} style={{ marginTop: 36, marginBottom: 15 }}>
            Please use this space, within 5000 characters, to tell us more about yourself: <br /> • Your ministry and mission involvements <br /> • Your
            spiritual journey <br /> • Your interests and passions <br /> • Your possible future ministry
          </div>
          <div className={classes.row}>
            <TextArea
              classNames={['borderless']}
              style={{ height: 300, width: '100%', color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.story}
              isDisabled
              placeholder=''
            />
          </div>
        </div>
      </div>
      <div className={classes.sectionsWrapper}>
        <div className={classes.section}>
          <div className={classes.title}>7. Learning Support</div>
          <div className={classes.row}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Do you have a specific learning difference, access requirement or other support requirement?' />
            <RadioInputGroup values={yesNoOptions} currentValue={yesNoOptions[data.additional_needs]} isInline isDisabled />
          </div>
          <div className={classes.row}>
            <InputLabel
              style={{ color: '#4A4A4A' }}
              text='If so, please provide details including any formal diagnosis. Disclosing this information does not impact the admissions decision:'
            />
            <TextArea
              classNames={['borderless']}
              style={{ height: 90, width: '100%', color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.additional_needs_description}
              isDisabled
              placeholder=''
            />
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.title}>8. Referees</div>
          <p className={classes.warning_text}>Referees should not be a close friend or family member</p>
          <div className={classes.text}>Referee 1 - an academic referee (who can comment on your suitability to study)</div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Name' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 138, width: 380, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.references_2_name}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Relationship to you' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 259, width: 259, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.references_2_relationship_to_you}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Email' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 138, width: 380, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.references_2_email}
              isDisabled
            />
          </div>
          <div className={classes.text}>Referee 2 – ministerial reference (who can comment on your ministry or other relevant experience)</div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Name' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 138, width: 380, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.references_1_name}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Relationship to you' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 259, width: 259, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.references_1_relationship_to_you}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Email' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 138, width: 380, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.references_1_email}
              isDisabled
            />
          </div>

          <div className={classes.row}>
            <div className={classes.text} style={{ marginBottom: 15 }}>
              If you have been out of the education system for longer than 10 years it may be more relevant to give the name of a recent employer or someone who
              can tell us about your suitability for study. Use the box below to explain why you have chosen that person.
            </div>
            <TextArea
              classNames={['borderless']}
              style={{ height: 90, width: '100%', color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.references_2_description}
              isDisabled
              placeholder=''
            />
          </div>
        </div>
      </div>
      <div className={classes.sectionsWrapper}>
        <div className={classes.section}>
          <div className={classes.title}>9. How did you hear about us?</div>
          <div className={classes.row}>
            <InputLabel style={{ color: '#4A4A4A' }} text='How did you hear about the Cambridge Theological Federation?' />
            <TextInput
              classNames={['borderless']}
              style={{ width: 518, color: '#9B9B9B', marginTop: 5, backgroundColor: '#F5F4F3' }}
              value={getValueInMultiSelect(data?.hear_about_us, hearAboutUsOption)}
              isDisabled
            />
          </div>

          {data?.other_hear_about_us && (
            <div className={classes.row}>
              <InputLabel style={{ color: '#4A4A4A' }} text='Other:' />
              <TextInput
                classNames={['borderless']}
                style={{ width: 518, color: '#9B9B9B', marginTop: 5, backgroundColor: '#F5F4F3' }}
                value={data?.other_hear_about_us}
                isDisabled
              />
            </div>
          )}
        </div>
      </div>

      <div className={classes.sectionsWrapper}>
        <div className={classes.section}>
          <div className={classes.title}>10. Declaration</div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: '#4A4A4A' }} text='Date' />
            <TextInput
              classNames={['borderless']}
              style={{ position: 'absolute', left: 368, width: 150, color: '#9B9B9B', backgroundColor: '#F5F4F3' }}
              value={data.Date}
              isDisabled
            />
          </div>
        </div>
      </div>
    </>
  )
}

ApplicationForm.propTypes = {
  data: PropTypes.object,
}
