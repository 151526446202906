import React from 'react'
import { VerticalTabs, TableBlock } from '@Root/components'
import { Redirect, Route, Switch, useParams, useHistory } from 'react-router'
import styles from './style.module.scss'
import { useSelector } from 'react-redux'
import { optionsSelectors, programmeSelectors } from '@Root/store'
import { permissionsSelectors } from '@Store/permissions'
import { studentsTableData, courseInitiativeTableData, courseReferenceTableData, courseRoleTableData, curriculumAccreditationTableData } from '../../lib'
import { suspendedMandatoryRules, suspendedSecretRule } from '@Root/configs'
import { StudentsTab } from './StudentsTab/StudentsTab'

export const DetailInformation = ({ styleConfig, isShowDefault }) => {
	const history = useHistory()
	const { programmeId } = useParams()
	const SLCLoan = useSelector(optionsSelectors.SLCLoan)
	const rulePermissions = useSelector(permissionsSelectors.rulePermissions)
	const permissions = useSelector(programmeSelectors.permissions)
	const options = useSelector(programmeSelectors.options)
	const defaultPathURL = '/home/studies/programmes'
	const DEFAULT_URL = `${defaultPathURL}/${programmeId}`
	const forms = () => {
		return [
			...(permissions?.tabs?.programmerStudentsList
				? [
						{
							name: 'Students',
							label: 'students',
							Component: (
								<div className={styles.table_padding}>
									<StudentsTab
										table={studentsTableData(options, programmeId, suspendedMandatoryRules, rulePermissions, SLCLoan)}
										styleConfig={styleConfig}
										isShowDefault={isShowDefault}
										suspendedSecretRule={suspendedSecretRule}
									/>
								</div>
							),
						},
				  ]
				: []),
			...(permissions?.tabs?.course_initiative
				? [
						{
							name: 'Course Initiative',
							label: 'course_initiatives',
							Component: (
								<div className={styles.table_padding}>
									<TableBlock title={'Course Initiative'} config={courseInitiativeTableData(programmeId, DEFAULT_URL, history)} />
								</div>
							),
						},
				  ]
				: []),
			...(permissions?.tabs?.course_reference
				? [
						{
							name: 'Course Reference',
							label: 'course_reference',
							Component: (
								<div className={styles.table_padding}>
									<TableBlock title={'Course Reference'} config={courseReferenceTableData(programmeId, DEFAULT_URL, history)} />
								</div>
							),
						},
				  ]
				: []),
			...(permissions?.tabs?.course_role
				? [
						{
							name: 'Course Role',
							label: 'course_role',
							Component: (
								<div className={styles.table_padding}>
									<TableBlock title={'Course Role'} config={courseRoleTableData(programmeId, DEFAULT_URL, history)} />
								</div>
							),
						},
				  ]
				: []),
			...(permissions?.tabs?.curriculum_accreditation
				? [
						{
							name: 'Curriculum Accreditation',
							label: 'curriculum_accreditation',
							Component: (
								<div className={styles.table_padding}>
									<TableBlock title={'Curriculum Accreditation'} config={curriculumAccreditationTableData(programmeId, DEFAULT_URL, history)} />
								</div>
							),
						},
				  ]
				: []),
		]
	}

	return permissions.tabs && forms()?.length > 0 ? (
		<div className={styles.wrapper_container}>
			<VerticalTabs tabs={forms()} isLink={true} defaultURL={`${defaultPathURL}/${programmeId}`} />

			<Switch>
				{forms().map(({ label, Component }) => {
					return <Route exact path={`${defaultPathURL}/${programmeId}/${label}`} component={() => Component} key={label} />
				})}
				<Redirect to={`${defaultPathURL}/${programmeId}/students`} />
			</Switch>
		</div>
	) : (
		<></>
	)
}
