import React from 'react'
import classes from '@Root/components/accordions/AccordionRoleManager/style.module.scss'
import { ArrowIcon, BlackTickIcon, CrossIcon, PlusIcon, TickImage } from '@Root/assets'
import { AccordionStudentsRoleManager } from '@Root/components'
import { withRouter } from 'react-router-dom'
import { Error } from '@Root/HOCs'
import { filterCategoriesFieldsAccordingToPermission } from '@Root/pages/Home/Admin/Role/lib/filterCategoriesFieldsAccordingToPermission'

class MainManager extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			opened: false,
			isOpenAddException: false,
			isOpenAddExceptionForSubForms: false,
			openedField: null,
			openedRole: null,
			isEditable: props.isEditable,
			selected: null,
			selectedSubCategory: null,
			selectedSubCategoryForm: null,
		}
	}

	componentDidMount() {
		const { initialMainAccordion, permissionType } = this.props
		if (initialMainAccordion === permissionType.label) {
			this.setState({ opened: true })
			const element = document.getElementById(initialMainAccordion)
			if (element) {
				element.scrollIntoView({ block: 'center', behavior: 'smooth' })
			}

			this.setState(this.getInitialData(true))
			return
		}
		this.setState(this.getInitialData())
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const props = this.props
		const data = props.data
		const prevData = prevProps.data
		if (JSON.stringify(data) !== JSON.stringify(prevData)) {
			this.onChangeDataListener()
		}
		if (props.isSuccess && !prevProps.isSuccess) {
			this.setState({ opened: false, ...this.getInitialData() })
		}
	}

	onChangeDataListener = () => {
		const { data } = this.props
		const { selected, selectedSubCategory } = this.state
		const updatedSelected = data.find(item => item.label === selected?.label)
		const updatedSubCategory = updatedSelected?.categories.find(item => item.label === selectedSubCategory?.label)
		this.setState({ selected: updatedSelected, selectedSubCategory: updatedSubCategory })
	}

	getInitialData = checkByProps => {
		const {
			data,
			permissionType: { label },
			initialMainCategoryId,
			initialCategoryId,
		} = this.props
		let initialData
		let initialSubCategory
		if (!checkByProps) {
			initialData = data[0]
			initialSubCategory = initialData?.categories.filter(item => item.permissions.includes(label))?.[0]
		} else {
			initialData = data.find(item => item.label === initialMainCategoryId)
			initialSubCategory = initialData?.categories.find(category => category.label === initialCategoryId)
		}

		const isActive = initialData?.permissions?.includes(label)

		return { selected: isActive ? initialData : null, selectedSubCategory: isActive ? (initialSubCategory ? initialSubCategory : null) : null }
	}

	setNewInitialSelectedDataAfterRemovedMainDataItem = id => {
		const {
			permissionType: { label },
			data,
		} = this.props
		const dataWithoutClicked = data.filter(item => item.label !== id).filter(item => item.permissions.includes(label))
		const newInitialSelectedItem = dataWithoutClicked[0]
		const newInitialSelectedSubCategory = newInitialSelectedItem?.categories[0]
		const hasItems = dataWithoutClicked.length > 0
		this.setState({ selected: hasItems ? newInitialSelectedItem : null, selectedSubCategory: hasItems ? newInitialSelectedSubCategory : null })
	}

	setNewInitialSubSelectedDataAfterRemovedMainSubItem = id => {
		const {
			permissionType: { label },
			data,
		} = this.props
		const { selected } = this.state
		const dataWithoutClicked = data
			.find(item => item.label === selected.label)
			.categories.filter(item => item.label !== id)
			.filter(item => item.permissions.includes(label))
		const newInitialSubItem = dataWithoutClicked[0]
		const hasItems = dataWithoutClicked.length > 0
		this.setState({ selectedSubCategory: hasItems ? newInitialSubItem : null })
	}

	setNewInitialSelectedDataAfterAddedMainDataItem = id => {
		const {
			data,
			permissionType: { label },
		} = this.props
		const dataByClicked = data.find(item => item.label === id)
		const initialSelected = dataByClicked?.categories.filter(item => item.permissions.includes(label))[0]
		this.setState({ selected: dataByClicked, selectedSubCategory: initialSelected || null })
	}

	setNewInitialSubSelectedDataAfterAddedMainDataItem = id => {
		const { data } = this.props
		const { selected } = this.state
		const dataByClicked = data.find(item => item.label === selected.label)
		const initialSelected = dataByClicked?.categories.find(item => item.label === id)
		this.setState({ selectedSubCategory: initialSelected })
	}

	onChangeCategoryStatus = (id, status) => {
		const {
			permissionType: { label },
			data,
			onDataChange,
		} = this.props
		const { selected } = this.state
		const modifiedData = data.map(item =>
			id === item.label ? { ...item, permissions: !status ? item.permissions.filter(permission => permission !== label) : [...item.permissions, label] } : item
		)
		onDataChange(modifiedData)
		if (!status) {
			if (selected?.label === id) {
				this.setNewInitialSelectedDataAfterRemovedMainDataItem(id)
			}
			return
		}
		this.setNewInitialSelectedDataAfterAddedMainDataItem(id)
	}

	onChangeSubCategoryStatus = (id, status) => {
		const {
			permissionType: { label },
			data,
			onDataChange,
		} = this.props
		const { selectedSubCategory, selected } = this.state
		const modifiedData = data.map(item =>
			selected.label === item.label
				? {
						...item,
						categories: item.categories.map(item =>
							item.label === id
								? {
										...item,
										permissions: item.permissions.includes(label)
											? item.permissions.filter(permissions => permissions !== label)
											: [...item.permissions, label],
								  }
								: item
						),
				  }
				: item
		)

		onDataChange(modifiedData)
		if (!status) {
			if (selectedSubCategory?.label === id) this.setNewInitialSubSelectedDataAfterRemovedMainSubItem(id)
			return
		}
		this.setNewInitialSubSelectedDataAfterAddedMainDataItem(id, status)
	}

	onSetActiveCategory = id => {
		const {
			data,
			permissionType: { label },
		} = this.props
		const selected = data.find(item => item.label === id)
		if (selected.permissions.includes(label)) {
			const subCategory = selected.categories.filter(item => item.permissions.includes(label))[0]
			this.setState({ selected, selectedSubCategory: subCategory || null })
		}
	}

	onSetActiveSubCategory = id => {
		const {
			permissionType: { label },
		} = this.props
		const { selected } = this.state
		const subCategory = selected?.categories?.find(category => category.label === id)
		const modifiedSubCategory = subCategory.permissions.includes(label) ? subCategory : { ...subCategory, permissions: [...subCategory.permissions, label] }
		this.setState({ selectedSubCategory: modifiedSubCategory })
	}

	onAddExceptions = () => {
		const {
			history,
			roleId,
			permissionType: { label },
			roleName,
			saveRoleIntoStorage,
			data,
		} = this.props

		const { selectedSubCategory, selected } = this.state
		const state = {
			categoryName: selectedSubCategory.name,
			categoryId: selectedSubCategory.label,
			fields: selectedSubCategory?.fields,
			mainCategoryId: selected.label,
			checkBy: label,
			roleId,
			roleName,
			isMainForm: true,
		}
		const categories = selected?.categories
		const savedData = data.map(item => (item.label === selected.label ? selected : item))
		saveRoleIntoStorage(savedData)
		this.saveLocationToLocalStorage()
		if (categories.length > 0) history.push(`/home/admin/manage-system-team/user-roles/${roleId}/manage-exceptions`, state)
	}

	saveLocationToLocalStorage = () => {
		const { location } = this.props
		localStorage.setItem('isNew', location.pathname.split('/')[5])
	}

	onOpenExceptionsForSubForms = () => {}

	onCloseExceptions = () => {
		this.setState({ isOpenAddException: false })
	}

	onCloseExceptionsForStudents = () => {
		this.setState({ isOpenAddExceptionStudentsRoles: false })
	}

	onSaveExceptions = (payload, prefix) => {
		const { data, onDataChange } = this.props
		const { selected, selectedSubCategory } = this.state
		const isSingle = !Array.isArray(payload)
		const modifiedData = data.map(item =>
			item.label === selected.label
				? {
						...item,
						categories: item.categories.map(category =>
							category.label === selectedSubCategory.label
								? { ...category, fields: !isSingle ? payload : this.changeFieldExceptionStatus(selectedSubCategory, payload, prefix) }
								: category
						),
				  }
				: item
		)
		this.setState({ isOpenAddException: false })
		onDataChange(modifiedData)
	}

	onCloseExceptionsSubForm = () => {
		this.setState({ isOpenAddExceptionForSubForms: false })
	}

	onOpenExceptionsForStudentsRoles = data => {
		const {
			history,
			roleId,
			permissionType: { label },
			roleName,
			saveRoleIntoStorage,
			data: oldData,
		} = this.props

		const { selectedSubCategory, selected } = this.state
		const state = {
			categoryName: data?.name,
			categoryId: selectedSubCategory.label,
			fields: data?.fields,
			subCategoryId: data.label,
			roleName,
			mainCategoryId: selected.label,
			checkBy: label,
			roleId,
			isMainForm: false,
		}
		this.saveLocationToLocalStorage()
		const savedData = oldData.map(item => (item.label === selected.label ? selected : item))
		saveRoleIntoStorage(savedData)
		history.push(`/home/admin/manage-system-team/user-roles/${roleId}/manage-exceptions`, state)
	}

	onSaveExceptionsSubForm = (payload, categoryLabel) => {
		const { data, onDataChange } = this.props
		const { selected, selectedSubCategory } = this.state
		const isSingle = !Array.isArray(payload)
		const modifiedData = data.map(item =>
			item.label === selected.label
				? {
						...item,
						categories: item.categories.map(category =>
							category.label === selectedSubCategory.label
								? {
										...category,
										categories: category.categories.map(subForm =>
											subForm.label === categoryLabel
												? { ...subForm, fields: !isSingle ? payload : this.changeFieldExceptionStatus(subForm, payload.label, payload.prefix) }
												: subForm
										),
								  }
								: category
						),
				  }
				: item
		)
		this.setState({ isOpenAddExceptionForSubForms: false })
		onDataChange(modifiedData)
	}

	changeFieldExceptionStatus = (array, id, prefix) => {
		const { permissionType } = this.props

		return array.fields.map(field =>
			field.label === id && field.prefix === prefix
				? {
						...field,
						permissions: field.permissions.includes(permissionType.label)
							? field.permissions.filter(permission => permission !== permissionType.label)
							: [...field.permissions, permissionType.label],
				  }
				: field
		)
	}

	onChangeCheckboxData = payload => {
		const { data, permissionType, onDataChange } = this.props
		const { selected, selectedSubCategory } = this.state
		const modifiedData = data.map(item =>
			item.label === selected.label
				? {
						...item,
						categories: item.categories.map(category =>
							category.label === selectedSubCategory.label
								? {
										...category,
										categories: category.categories.map(subForm =>
											subForm.label === payload.label
												? {
														...subForm,
														permissions: payload.permissions.includes(permissionType.label)
															? payload.permissions.filter(item => item !== permissionType.label)
															: [...payload.permissions, permissionType.label],
														fields: subForm.fields.map(field =>
															field.permissions.includes(permissionType.label) ? field : { ...field, permissions: [...field.permissions, permissionType.label] }
														),
												  }
												: subForm
										),
								  }
								: category
						),
				  }
				: item
		)
		onDataChange(modifiedData)
	}

	render() {
		const {
			state: { opened, selected, selectedSubCategory },
			props: { isEditable, permissionType, data, initialSubCategoryId },
		} = this
		const categories = selected?.categories
		const subCategoryFields = selectedSubCategory?.fields
		const subCategoryCategories = selectedSubCategory?.categories
		const hasIncludeOnly = Array.isArray(selectedSubCategory?.include_only)
		const filteredFieldsAccordingToPermission = filterCategoriesFieldsAccordingToPermission(subCategoryFields, permissionType.label)
		return (
			<div className={`${classes.accordion_item}, ${opened && `${classes.accordion_item__opened}`}`}>
				<div
					className={`${classes.accordion_item__line}`}
					onClick={() => {
						this.setState({ opened: !opened })
					}}
				>
					<h3 className={classes.accordion_item__title}>{permissionType.title}</h3>
					<img className={classes.accordion_item__icon} src={ArrowIcon} alt={'arrow'} />
				</div>
				<div className={classes.accordion_item__inner}>
					<div className={classes.accordion_item__content}>
						<span className={classes.accordion_item__subTitle}>Categories</span>
						<div className={classes.accordion_item__categories} style={{ gridTemplateColumns: `repeat(${data?.length}, max-content)` }}>
							{data?.map(({ name, label, permissions = [] }) => {
								const isChecked = permissions.includes(permissionType.label)
								const isActive = label === selected?.label
								return (
									<div key={label}>
										<div
											className={`${classes.accordion_item__categories__category} ${
												isChecked ? classes.accordion_item__categories__category__selected : classes.accordion_item__categories__category__removed
											}  ${isActive ? classes.accordion_item__categories__category__active : ''}`}
										>
											<span onClick={!isChecked && isEditable ? () => this.onChangeCategoryStatus(label, true) : () => this.onSetActiveCategory(label)}>
												{name}
											</span>
											{isChecked && isEditable ? (
												<img
													src={BlackTickIcon}
													className={classes.accordion_item__categories__category_tick}
													alt={'tick'}
													onClick={() => this.onChangeCategoryStatus(label, false)}
												/>
											) : (
												isEditable && <img src={CrossIcon} className={classes.accordion_item__categories__category_cross} alt={'cross'} />
											)}
										</div>
									</div>
								)
							})}
						</div>
						{categories?.length > 0 && (
							<div className={`PageNavLinks ${classes.pageNavLinks_role_manager}`}>
								{categories.map(subTab => {
									const { name, label, permissions } = subTab
									const isActive = label === selectedSubCategory?.label
									const isChecked = permissions.includes(permissionType.label)
									return (
										<div className={classes.accordion_item__subtab} key={label}>
											<span
												className={isActive ? classes.accordion_item__subtab__item_active : classes.accordion_item__subtab__item}
												onClick={!isChecked && isEditable ? () => this.onChangeSubCategoryStatus(label, true) : () => this.onSetActiveSubCategory(label)}
												key={label}
											>
												{name}
											</span>
											{isEditable && (
												<>
													{isChecked ? (
														<img
															src={TickImage}
															className={classes.accordion_item__subtab__item_tick}
															alt={'tick'}
															onClick={() => this.onChangeSubCategoryStatus(label, false)}
														/>
													) : (
														<img
															src={CrossIcon}
															className={classes.accordion_item__subtab__item_cross}
															onClick={() => this.onChangeSubCategoryStatus(label, true)}
															alt={'cross'}
														/>
													)}
												</>
											)}
										</div>
									)
								})}
							</div>
						)}
						{subCategoryFields?.length > 0 && (
							<div className={classes.accordion_item_excepts_block}>
								{(hasIncludeOnly ? (
									selectedSubCategory.include_only.includes(permissionType.label)
								) : filteredFieldsAccordingToPermission.length > 0 ? (
									true
								) : (
									!permissionType.skipTableExceptions
								)) ? (
									<div>
										<div className={classes.accordion_item_excepts_block_title}>Excepts</div>
										<div className={classes.accordion_item_excepts_actions}>
											{isEditable ? (
												<div className={classes.accordion_item_excepts_block_add_button} onClick={this.onAddExceptions}>
													<img src={PlusIcon} alt='' />
													<span>Add</span>
												</div>
											) : (
												filteredFieldsAccordingToPermission.filter(({ permissions }) => !permissions.includes(permissionType.label)).length === 0 && (
													<span className={classes.accordion_item__excepts_without_label}>No excepts yet</span>
												)
											)}
											<div className={classes.disabled_fields}>
												{filteredFieldsAccordingToPermission?.map(({ permissions, name, label, prefix }) => {
													const isDisabled = !permissions.includes(permissionType.label)
													return (
														isDisabled && (
															<div
																className={`${classes.accordion_item__categories__category} ${classes.accordion_item__categories__category__selected}`}
																key={name}
															>
																<span>{name}</span>
																{isEditable && <img src={CrossIcon} alt='' onClick={() => this.onSaveExceptions(label, prefix)} />}
															</div>
														)
													)
												})}
											</div>
										</div>
									</div>
								) : (
									<div className={classes.noDataInCategory}>No data you can edit in this category</div>
								)}
							</div>
						)}
						{subCategoryCategories ? (
							<AccordionStudentsRoleManager
								isEditable={isEditable}
								data={subCategoryCategories}
								onOpenExceptionsModal={this.onOpenExceptionsForStudentsRoles}
								onChangeFieldStatus={this.onSaveExceptionsSubForm}
								permissionType={permissionType}
								initialCategoryId={initialSubCategoryId}
								onChangeData={this.onChangeCheckboxData}
							/>
						) : (
							subCategoryCategories === null && <Error>No subcategories available</Error>
						)}
					</div>
				</div>
			</div>
		)
	}
}

export const MainAccordionManager = withRouter(MainManager)
