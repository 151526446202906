import React from 'react'
import classes from './TEIDLogo.module.scss'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { CtfLogo } from '@Root/assets/icons'

const TEIDLogo = ({ style, link }) => (
  <div className={classes.TEIDLogo} style={style}>
    {link ? (
      <Link to={link} className={classes.link}>
        <img src={CtfLogo} alt='Logo' height={50} />
      </Link>
    ) : (
      <img src={CtfLogo} alt='Logo' height={50} />
    )}
  </div>
)

TEIDLogo.propTypes = {
  style: PropTypes.object,
  link: PropTypes.string,
}

TEIDLogo.defaultProps = {
  style: {},
}

export default TEIDLogo
