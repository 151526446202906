import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Redirect } from 'react-router-dom'
import styles from './style.module.scss'
import { CancelButton, SaveButton, SearchField } from '@Root/components'
import { BlackTickIcon, CrossIcon } from '@Root/assets'
import { highlightMatchedString } from '@Helpers/highlightMatchedString'
import { Error } from '@Root/HOCs'
import { getFromLocalStorage, setToLocalStorage } from '@Root/helpers'
import { filterCategoriesFieldsAccordingToPermission } from '../lib/filterCategoriesFieldsAccordingToPermission'

const PARENT_URL = '/home/admin/manage-system-team/user-roles'
export const ManageExceptionPage = () => {
  const location = useLocation()
  const history = useHistory()
  const [searchValue, setSearchValue] = useState('')
  const [data, setData] = useState([])
  const [role, setRole] = useState([])
  const [isNew, setIsNew] = useState(false)
  const { categoryName = '', categoryId = '', fields = [], checkBy = '', roleId, mainCategoryId, isMainForm, subCategoryId, roleName } = location?.state || {}

  const mainState = useMemo(
    () => ({
      categoryName,
      categoryId,
      checkBy,
      roleId,
      mainCategoryId,
      subCategoryId,
      isInitialEditMode: true,
    }),
    [categoryId, categoryName, checkBy, mainCategoryId, roleId, subCategoryId]
  )
  useEffect(() => {
    const role = getFromLocalStorage('saved_role_data')
    if (role) {
      setRole(role)
    }
  }, [])

  useEffect(() => {
    setToLocalStorage('role_form_params', { ...mainState, isMainForm })
    setIsNew(localStorage.getItem('isNew') === 'new')
  }, [])

  useEffect(() => {
    setData(fields)
  }, [])

  const onSearch = value => {
    setSearchValue(value)
  }

  const redirectToParent = () => {
    if (isNew) {
      history.goBack()
    } else {
      history.push(`${PARENT_URL}/${roleId}`)
    }
  }

  const onSave = () => {
    const modifiedData = {
      ...role,
      permissions: role?.permissions.map(permission =>
        permission.label === mainCategoryId
          ? {
              ...permission,
              categories: permission.categories.map(category =>
                category.label === categoryId
                  ? {
                      ...category,
                      fields: isMainForm ? data : category.fields,
                      categories: !isMainForm
                        ? category.categories.map(category => (category.label === subCategoryId ? { ...category, fields: data } : category))
                        : category.categories,
                    }
                  : category
              ),
            }
          : permission
      ),
    }
    setToLocalStorage('saved_role_data', modifiedData)
    setToLocalStorage('role_form_params', {
      ...mainState,
      isMainForm,
      fields: data,
    })
    redirectToParent()
  }

  const onCancel = () => {
    setToLocalStorage('role_form_params', { ...mainState, isMainForm: false })
    redirectToParent()
  }

  const onChangeFieldStatus = field => {
    setData(prevState => {
      return prevState.map(item =>
        item.label === field.label && field.prefix === item.prefix
          ? {
              ...field,
              permissions: field.permissions.includes(checkBy)
                ? field.permissions.filter(permission => permission !== checkBy)
                : [...field.permissions, checkBy],
            }
          : item
      )
    })
  }

  const filteredFields = useMemo(() => filterCategoriesFieldsAccordingToPermission(data, checkBy), [checkBy, data])
  const exceptions = useMemo(() => data.filter(({ permissions }) => !permissions.includes(checkBy)), [data, checkBy])
  if (!location.state) return <Redirect to={PARENT_URL} />
  return (
    <div className={styles.page}>
      <h1>
        Add Excepts for: {categoryName} {roleName && `(${roleName})`}
      </h1>
      <p>Excepts won't display for the user</p>
      <div className={styles.searchField}>
        <label>Search: </label>
        <SearchField value={searchValue} onChange={onSearch} maxLength={255} />
      </div>
      <div className={styles.grid}>
        <div className={styles.column}>
          <h5>All fields</h5>
          <div>
            {filteredFields.map(field => {
              const { name, permissions, label, prefix } = field
              const isDisabled = !permissions.includes(checkBy)
              const { beforeMatch, afterMatch, matchValue, isMatched } = highlightMatchedString(name, searchValue)
              return (
                <div
                  className={`${styles.item} ${!isDisabled ? styles.active_item : styles.removed_item}`}
                  onClick={isDisabled ? () => onChangeFieldStatus(field, false) : null}
                  key={label + prefix}
                >
                  <span className={styles.item_word}>
                    {beforeMatch && <span>{beforeMatch}</span>} <span className={isMatched ? styles.match_value : ''}>{matchValue}</span>
                    {afterMatch && <span>{afterMatch}</span>}
                  </span>
                  {!isDisabled ? (
                    <img src={CrossIcon} className={styles.item_cross} alt={'cross'} onClick={() => onChangeFieldStatus(field, true)} />
                  ) : (
                    <img src={BlackTickIcon} alt='' className={styles.item_tick} />
                  )}
                </div>
              )
            })}
          </div>
        </div>
        <div className={styles.column}>
          <h5>Excepts</h5>
          <div>
            {exceptions.length > 0 ? (
              exceptions.map(field => {
                const { name, label, prefix } = field
                const { beforeMatch, afterMatch, matchValue, isMatched } = highlightMatchedString(name, searchValue)
                return (
                  <div className={`${styles.item} ${styles.active_item}`} key={label + prefix}>
                    <span className={styles.item_word}>
                      {beforeMatch && <span>{beforeMatch}</span>} <span className={isMatched ? styles.match_value : ''}>{matchValue}</span>
                      {afterMatch && <span>{afterMatch}</span>}
                    </span>
                    <img src={CrossIcon} className={styles.item_cross} alt={'cross'} onClick={() => onChangeFieldStatus(field, true)} />
                  </div>
                )
              })
            ) : (
              <Error>You haven't added any field as exception yet. To add any field to axceptions you should click on 'x' button near its title</Error>
            )}
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton onClick={onSave}>Save</SaveButton>
      </div>
    </div>
  )
}
