import React, { useEffect } from 'react'
import classes from './style.module.scss'
import { SectionWrapper } from '@Root/HOCs'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { userActions, userPermissionsSelectors, userSelectors } from '@Store/admin'
import { EditPermissions } from './Edit'
import { userPermissionsActions } from '@Store/admin'
import { roleActions } from '@Root/store'

export const RoleManagerPage = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { userId } = useParams()
	const options = useSelector(userSelectors.options).static
	const permissions = useSelector(userPermissionsSelectors.permissions)

	useEffect(() => {
		dispatch(roleActions.getStaticOptions())
		dispatch(userActions.getStaticOptions())
	}, [dispatch])

	useEffect(() => {
		if (userId) dispatch(userPermissionsActions.get({ id: userId }))
	}, [dispatch, userId])

	const saveHandler = data => {
		console.log(data)
	}

	return (
		<div className={classes.page} style={{ marginTop: 235 }}>
			<SectionWrapper hasBackButton backButtonText='User' clickBackButtonHandler={() => history.push(`/home/admin/manage-system-team/system-users/${userId}`)}>
				{permissions && <EditPermissions initialValue={permissions} onSave={saveHandler} roles={options.roles} />}
			</SectionWrapper>
		</div>
	)
}
