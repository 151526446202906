import React, { useState, useEffect } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { FormWrapper } from '@Root/HOCs'
import { InputLabel, MultiSelect } from '@Root/components'
import { switchEmptyStringsToNullsInObject } from '@Root/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { useError } from '@Root/hooks'
import { fieldTooltipManagerActions, fieldTooltipManagerSelectors, modalActions } from '@Root/store'
import { academicActions, personSelectors, staffActions, staffSelectors } from '@Store/contact/person'
import { Redirect, useHistory } from 'react-router'
import { permissionsActions } from '@Store/permissions'
import { contactSelectors } from '@Root/store'

const PARENT_URL = '/home/contacts/all-contacts'
const relationShip = 'Staff'
const titles = { onRead: 'Staff Details', onEdit: 'Edit Staff Details' }
export const StaffForm = ({ contactId }) => {
	const history = useHistory()
	const [data, setData] = useState({})
	const [isEditable, setIsEditable] = useState(false)
	const { errors, setError } = useError()
	const relationships = useSelector(personSelectors.relationships)
	const isLoading = useSelector(staffSelectors.isLoading)
	const isSuccess = useSelector(staffSelectors.isSuccess)
	const permissions = useSelector(staffSelectors.permissions)
	const isPermissionsLoaded = useSelector(staffSelectors.isPermissionsLoaded)
	const staticOptions = useSelector(staffSelectors.options).static
	const staff = useSelector(staffSelectors.staff)
	const dispatch = useDispatch()
	const descriptions = useSelector(fieldTooltipManagerSelectors.contactDescriptions).person.staff
	const contactPermissions = useSelector(contactSelectors.permissions)

	useEffect(() => {
		if (contactId) dispatch(academicActions.get({ contactId }))
	}, [contactId, dispatch])

	useEffect(() => {
		const hasOptions = Object.keys(staticOptions).length > 0
		if (!hasOptions) dispatch(staffActions.getStaticOptions())
	}, [dispatch, staticOptions])

	useEffect(() => {
		dispatch(fieldTooltipManagerActions.getContact({ entity: 'contacts', entity_type: 'person', entity_sub_type: 'staff' }))
	}, [dispatch])

	useEffect(() => {
		if (contactId) {
			dispatch(staffActions.get({ contactId }))
			dispatch(permissionsActions.getMain({ entity: 'staff', contactId: Number(contactId) }))
		}
	}, [contactId, dispatch])

	useEffect(() => {
		if (isSuccess) setIsEditable(false)
	}, [isSuccess])

	useEffect(() => {
		if (staff) setData(staff)
	}, [staff])

	const onCancel = () => {
		setData(staff)
		setIsEditable(false)
	}

	const onDelete = () => {
		new Promise((resolve, reject) => {
			dispatch(
				modalActions.showModal('ConfirmationModal', {
					text: `The "${relationShip}" relationship will be removed?`,
					clickRejectButtonHandler: reject,
					clickResolveButtonHandler: resolve,
				})
			)
		})
			.then(() => {
				const relationshipId = relationships.find(item => item.label === relationShip).value
				dispatch(staffActions.delete({ contactId, relationshipId, history }))
			})
			.finally(() => {
				dispatch(modalActions.hideModal())
			})
	}
	const onSave = () => {
		const modifiedData = switchEmptyStringsToNullsInObject(data)
		dispatch(staffActions.edit({ ...modifiedData, id: contactId }))
	}

	const handleChangeInput = (field, value) => {
		setData({ ...data, [field]: value })
	}

	const toggleEditMode = () => {
		setIsEditable(prevState => !prevState)
	}

	const onOpenDescriptionModal = (description, title) => {
		new Promise((resolve, reject) => {
			dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
		}).finally(() => dispatch(modalActions.hideModal()))
	}

	if (!contactPermissions.tabs?.staff) return <Redirect to={PARENT_URL} />
	const { form, fields } = permissions
	return (
		<FormWrapper
			buttons={isEditable ? ['cancel', ...(form?.delete ? ['delete'] : []), 'save'] : []}
			buttonsNames={[{ button: 'delete', name: 'Delete this staff member' }]}
			buttonsAreDisabled={isLoading}
			isSpinning={isLoading}
			clickCancelButtonHandler={onCancel}
			clickDeleteButtonHandler={onDelete}
			clickSaveButtonHandler={onSave}
			canEdit={false}
			clickToggleEditModeButtonHandler={toggleEditMode}
			isEditable={isEditable}
			readTitle={titles.onRead}
			editTitle={titles.onEdit}
		>
			<div className={classes.rowsWrapper}></div>
		</FormWrapper>
	)
}

StaffForm.propTypes = {
	permissions: PropTypes.arrayOf(PropTypes.string),
	institution_ids: PropTypes.arrayOf(PropTypes.number),
	deleteHandler: PropTypes.func,
}

StaffForm.defaultProps = {
	permissions: [],
	initialData: {
		institution_ids: [],
	},
	deleteHandler: () => {},
}
