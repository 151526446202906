import {
  adminEmail,
  applicantEmail,
  newCreatedUserEmail,
  emailForUsersAboutUpdatesEmail,
  resetPasswordEmail,
  applicantEmailSuccessfulSubmission,
} from './emailsConf'
import { AdminTable } from '@Root/pages/Home/Admin/Table'
import { ApplicationFormInstructions } from '@Root/pages/Home/Admin/ApplicationFormInstructions'
import { PageTemplate } from '@Root/pages/Home/Admin/PageTemplate'
import { EmailsTemplate } from '@Root/pages/Home/Admin/EmailsTemplate'
import { TableManager } from '@Root/pages/Home/Admin/TableManager'
import { AdditionalFieldsPage } from '@Root/pages/Home/Admin/AdditionalFields'

export const contactsLinks = [
  { name: 'All Contacts', path: 'all-contacts', label: 'all_contacts' },
  { name: 'Students', path: 'students', label: 'student' },
  { name: 'Academics', path: 'academics', label: 'academic' },
  { name: 'Staff', path: 'staff', label: 'staff' },
  { name: 'Clergy', path: 'clergy', label: 'clergy' },
  { name: 'Alumni', path: 'associations', label: 'association' },
  { name: 'Churches', path: 'churches', label: 'church' },
  { name: 'Dioceses', path: 'dioceses', label: 'diocese' },
  { name: 'Training Facilities', path: 'training-facilities', label: 'trainingfacility' },
  { name: 'Other', path: 'other', label: 'other' },
]

export const studiesLinks = [
  { name: 'Student Engagements', path: 'engagements', label: 'studentEngagements' },
  { name: 'Student Course Sessions', path: 'records-of-studies', label: 'courseSession' },
  { name: 'Module Instances ', path: 'module-instances', label: 'moduleInstance' },
  { name: 'Programmes', path: 'programmes', label: 'programmer' },
  { name: 'Modules', path: 'modules', label: 'module' },
  { name: 'Postgrad Student Details', path: 'postgrad-student-details', label: 'posrgradStudentDetails' },
  { name: 'Non-active students', path: 'non-active', label: 'suspensions' },
]

export const admissionsLinks = [
  { name: 'Admissions Details', path: 'admissions-details', label: 'admissionDetail' },
  { name: 'Applications', path: 'applications', label: 'application' },
]

export const financeLinks = [
  { name: 'Tuition Fees', path: 'tuition-fees', label: 'studentCourseSessionFee' },
  { name: 'Fundings', path: 'fundings', label: 'fundingAndMonitoring' },
]

export const gdprLinks = [
  { name: 'GDPR Log', path: 'gdpr-log', label: 'gdpr_log' },
  { name: 'Data Pruning', path: 'data-pruning', label: 'data_pruning' },
]

export const adminLinks = [
  {
    title: 'Manage system team',
    path: 'manage-system-team',
    label: 'manage_system_team',
    subLinks: [
      { name: 'System Users', path: 'system-users', Component: AdminTable, label: 'system_user' },
      { name: 'User Roles', path: 'user-roles', Component: AdminTable, label: 'user_role' },
      {
        name: 'Email for new created user',
        path: 'email-for-new-created-user',
        Component: EmailsTemplate,
        label: 'email_new_created_user',
        hideInTableManager: true,
        conf: newCreatedUserEmail,
      },
      {
        name: 'Email for users about updates',
        path: 'email-for-users-about-updates',
        Component: EmailsTemplate,
        label: 'email_users_about_updates',
        hideInTableManager: true,
        conf: emailForUsersAboutUpdatesEmail,
      },
      {
        name: 'Reset password email',
        path: 'reset-password-email',
        Component: EmailsTemplate,
        label: 'reset_password_email',
        hideInTableManager: true,
        conf: resetPasswordEmail,
      },
    ],
  },
  { title: 'Snapshots', path: 'snapshots', subLinks: [], Component: AdminTable, label: 'snapshot' },
  {
    title: 'Application form',
    path: 'application-form',
    label: 'application_form',
    subLinks: [
      {
        name: 'Application Form Instructions',
        path: 'application-form-instructions',
        Component: ApplicationFormInstructions,
        label: 'application_form_instruction',
        hideInTableManager: true,
      },
      { name: 'Emails', path: 'emails', Component: PageTemplate, label: 'email', hideInTableManager: true },
      {
        name: 'Email for TEID admin',
        path: 'email-for-teid-admin',
        Component: EmailsTemplate,
        label: 'email_teid_admin',
        hideInTableManager: true,
        conf: adminEmail,
      },
      {
        name: 'Email for TEID applicants',
        path: 'email-for-teid-applicants',
        Component: EmailsTemplate,
        label: 'email_teid_applicant',
        hideInTableManager: true,
        conf: applicantEmail,
      },
      {
        name: 'Email for applicant after finish form',
        path: 'email-for-applicant-after-finish-form',
        Component: EmailsTemplate,
        label: 'email_teid_applicant_successful_submission',
        hideInTableManager: true,
        conf: applicantEmailSuccessfulSubmission,
      },

      {
        name: 'Questions for applicants',
        path: 'questions-for-applications',
        Component: AdminTable,
        label: 'application_form_instruction',
        hideInTableManager: false,
      },
    ],
  },
  {
    title: 'Data',
    path: 'data',
    label: 'data',
    subLinks: [
      { name: 'Institutions', path: 'institutions', label: 'institution', Component: AdminTable },
      { name: 'Qualifications', path: 'qualifications', label: 'qualification', Component: AdminTable },
      { name: 'Venues', path: 'venues', label: 'venue', Component: AdminTable },
      { name: 'Student Types', path: 'student-types', label: 'student_type', Component: PageTemplate, hideInTableManager: true },
      { name: 'Accommodation', path: 'accommodation', label: 'accommodation', Component: PageTemplate, hideInTableManager: true },
      { name: 'Programme Types', path: 'programme-types', label: 'programme_type', Component: PageTemplate, hideInTableManager: true },
      { name: 'Statuses', path: 'statuses', label: 'status', Component: PageTemplate, hideInTableManager: true },
      { name: 'Fee Statuses', path: 'fee-statuses', label: 'fee_status', Component: PageTemplate, hideInTableManager: true },
      { name: 'Awarding body', path: 'awarding-body', label: 'awarding_body', Component: PageTemplate, hideInTableManager: true },
      { name: 'Session Year', path: 'session-year', label: 'session_year', Component: AdminTable },
      { name: 'Ordinand', path: 'ordinand', label: 'ordinand', Component: PageTemplate, hideInTableManager: true },
      { name: 'TEI Code', path: 'tei_code', label: 'tei_code', Component: PageTemplate, hideInTableManager: true },
      { name: 'HESA Root URL', path: 'hesa_root_url', label: 'hesa_root_url', Component: PageTemplate, hideInTableManager: true },
      {
        name: 'Current reference year',
        path: 'current_reference_year',
        label: 'current_reference_year',
        Component: PageTemplate,
        hideInTableManager: true,
      },
    ],
  },
  {
    title: 'Table Manager',
    path: 'table-manager',
    label: 'table_manager',
    hasSlug: true,
    hideInTableManager: true,
    subLinks: [
      { name: 'Contacts', path: 'contacts', Component: TableManager },
      { name: 'Studies', path: 'studies', Component: TableManager },
      { name: 'Admissions', path: 'admissions', Component: TableManager },
      { name: 'Finance', path: 'finance', Component: TableManager },
      { name: 'GDPR', path: 'gdpr', Component: TableManager },
      { name: 'Admin', path: 'admin', Component: TableManager },
    ],
  },
  {
    title: 'Additional fields',
    path: 'additional-fields',
    label: 'additional_field',
    hideInTableManager: true,
    subLinks: [],
    Component: AdditionalFieldsPage,
  },
  {
    title: 'Moodle links',
    path: 'moodle-links',
    label: 'moodle_link',
    hideInTableManager: false,
    subLinks: [],
    Component: AdminTable,
  },
]

export const pagesLinks = [
  { name: 'Contacts', path: 'contacts' },
  { name: 'Studies', path: 'studies' },
  { name: 'Admissions', path: 'admissions' },
  { name: 'Finance', path: 'finance' },
  { name: 'GDPR', path: 'gdpr' },
  { name: 'Admin', path: 'admin' },
  { name: 'Help Text', path: 'help-text', label: 'help_text' },
]

export const pagesLinksTextHelp = [
  { name: 'Contacts', path: 'contacts' },
  { name: 'Studies', path: 'studies' },
  { name: 'Admin', path: 'admin' },
]

export const tableManagerMenuTabLinks = {
  contacts: [...contactsLinks, { name: 'Additional fields', path: 'additional-fields' }],
  studies: studiesLinks,
  admissions: admissionsLinks,
  finance: financeLinks,
  gdpr: gdprLinks,
  admin: adminLinks.map(({ title, path, hideInTableManager, subLinks }) => {
    const newObj = { name: title, path, hideInTableManager }
    return !subLinks
      ? { ...newObj }
      : {
          ...newObj,
          subLinks: subLinks.filter(({ hideInTableManager }) => !hideInTableManager).map(({ name, path }) => ({ name, path, isShifted: true })),
        }
  }),
}

export const helperTextMenuTabLinks = {
  contacts: [
    { name: 'Person', isShifted: false, path: 'person' },
    {
      name: 'Rollover',
      isShifted: true,
      path: 'rollover',
      subLinks: [
        { name: 'Rollover newly recruited students', path: 'students_rollover_newly', isShifted: true },
        { name: 'Rollover Continuing student', path: 'students_rollover_continuing', isShifted: true },
      ],
    },
    {
      name: 'Hesa Details',
      isShifted: true,
      path: 'hesa-details',
      subLinks: [
        { name: 'Student', path: 'student', isShifted: true },
        { name: 'Engagements', path: 'engagements', isShifted: true },
        { name: 'Disability', path: 'disabilities', isShifted: true },
        { name: 'Language Proficiency', path: 'language_proficiencies', isShifted: true },
        { name: 'Module instance', path: 'modules_instances', isShifted: true },
        { name: 'Student Course Session', path: 'student_course_sessions', isShifted: true },
      ],
    },
    { name: 'Students', isShifted: true, path: 'students' },
    { name: 'Academics', isShifted: true, path: 'academics' },
    { name: 'Staff', isShifted: true, path: 'staff' },
    { name: 'Clergy', isShifted: true, path: 'clergy' },
    { name: 'Alumni', isShifted: true, path: 'associations' },
    { name: 'Other', isShifted: true, path: 'other' },
    { path: 'organisation', isShifted: false, name: 'Organisation' },
    { name: 'Churches', isShifted: true, path: 'churches' },
    { name: 'Dioceses', isShifted: true, path: 'dioceses' },
    { name: 'Training Facilities', isShifted: true, path: 'training-facilities' },
  ],
  studies: [
    { name: 'Records of Studies', path: 'records-of-studies' },
    { name: 'Programmes', path: 'programmes' },
    { name: 'Course Initiative', path: 'programmes_course_initiative', isShifted: true },
    { name: 'Course Reference', path: 'programmes_course_reference', isShifted: true },
    { name: 'Course Role', path: 'programmes_course_role', isShifted: true },
    { name: 'Curriculum Accreditation', path: 'programmes_curriculum_accreditation', isShifted: true },
    { name: 'Modules', path: 'modules' },
    { name: 'Module Subject', path: 'module-subject', isShifted: true },
    { name: 'Module Cost Center', path: 'module-cost-center', isShifted: true },
    { name: 'Module Role', path: 'module-role', isShifted: true },
    { name: 'Engagements', path: 'engagements' },
    { name: 'Rollover leavers', path: 'engagements_rollover', isShifted: true },
    { name: 'Collaborative Provision', path: 'engagement_collaborative_provision', isShifted: true },
    { name: 'Leaver', path: 'engagement_leaver', isShifted: true },
    { name: 'Qualification Subject', path: 'qualification_subject', isShifted: true },
    { name: 'Qualification Award', path: 'engagement_qualification_award', isShifted: true },
    { name: 'Entry Qualification Award', path: 'entry_qualification_award', isShifted: true },
    { name: 'Qualification Award Accreditation', path: 'qualification_award_accreditation', isShifted: true },
    { name: 'Entry Profile', path: 'engagement_entry_profile', isShifted: true },
    { name: 'Accreditation Aim', path: 'engagement_accreditation_aim', isShifted: true },
    { name: 'Student Initiatives', path: 'engagement_student_initiatives', isShifted: true },
    { name: 'Postgrad Student Details', path: 'postgrad-student-details' },
    { name: 'Fees', isShifted: false, path: 'fees' },
    { name: 'Funding and Monitoring', isShifted: false, path: 'funding-and-monitoring' },
    { name: 'Admissions', isShifted: false, path: 'admissions' },
  ],
  admin: [
    {
      name: 'Manage system team',
      path: 'manage-system-team',
      subLinks: [{ name: 'System Users', path: 'system-users', isShifted: true }],
    },
    { name: 'Snapshots', path: 'snapshots' },
    {
      name: 'Data',
      path: 'data',
      subLinks: [
        { name: 'Institutions', path: 'institutions', isShifted: true },
        { name: 'Qualifications', path: 'qualifications', isShifted: true },
        { name: 'Venues', path: 'venues', isShifted: true },
        { name: 'Session Year', path: 'session_year', isShifted: true },
      ],
    },
  ],
}
